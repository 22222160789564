@font-face {
    font-family: 'SCProsperSans';
    src: url(./SCProsperSans-Light.ttf);
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'SCProsperSans';
    src: url(./SCProsperSans-Regular.ttf);
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'SCProsperSans';
    src: url(./SCProsperSans-Medium.ttf);
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'SCProsperSans';
    src: url(./SCProsperSans-Bold.ttf);
    font-weight: bold;
    font-style: italic;
}