//Colours Definition
$alice-blue: #E8F1FD;
$picton-blue: #43A9E1;
$resolution-blue : #021E7F;
$dark-electric-blue: #5d6679;
$color-white : #FFFFFF;





//XPNA pallette
$backgroundColor : #072565;
$color-blue-very-desaturated-dark: #423970;
$secondary: #062565;
$color-koamaru-deep : #062565;
$color-pastel-blue-light : #DEE3EC;
$color-box-shadow1: #0000001f;
$color-box-shadow2: #0000003d;
$color-blue-very-desaturated-dark: #423970;
$color-blue-moderate: #5fa0d6;
$color-blue-moderate2: #4e6eb6;
$color-blue-dark: #2c6fa0;
$color-blue-mostly-dark: #5a5282;
$color-blue-moderate-dark: #685aa9;
$color-blue-soft: #4d93fb;
$color-blue-grayish: #a2afc2;
$color-koamaru-deep : #062565;
$color-pastel-blue : #b1bace;
$color-pastel-blue-light : #DEE3EC;
$color-sapphire: #015ABB;
$color-light-azure-blue: #70B5FA;
$color-pale-blue: #B6D3F3;
$color-windows-blue: #00A4EF;

$color-gray-light: #eeeeee;
$color-gray-medium-light: #dddddd;
$color-gray-medium: #c1c1c1;
$color-gray-medium-dark: #5a5a5a;
$color-gray-dark: #4d4d4d;
$color-gray-very-dark: #3d3d3d;
$color-quick-silver: #A1A1A1;
$color-gray-regular: #dddddd;
$color-gray-semi-dark: #7b7b7b;



// Mixin Function for paddings and margins
$max: 100;
$offset: 1;
$unit: "px";
@mixin spacing-loop($className, $styleName) {
  $i: 0;
  @while $i <= $max {
    #{$className + $i} {
      #{$styleName}: #{$i + $unit} !important;
    }
    $i: $i + $offset;
  }
}


@mixin customScrollBar {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background-color: rgba(88, 38, 38, 0.12);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-button:single-button {
    display: block;
    border-style: solid;
    height: 5px;
    width: 10px;
  }
  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent $secondary transparent;
  }
  &::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 5px 5px 0 5px;
    border-color: $secondary transparent transparent transparent;
  }
  &::-webkit-scrollbar-button:single-button:horizontal:decrement {
    border-width: 5px 5px 5px 0px;
    border-color: transparent $secondary transparent transparent;
  }
  &::-webkit-scrollbar-button:single-button:horizontal:increment {
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent $secondary;
  }
}

// Margins
@include spacing-loop(".mt-", "margin-top");
@include spacing-loop(".mr-", "margin-right");
@include spacing-loop(".mb-", "margin-bottom");
@include spacing-loop(".ml-", "margin-left");
@include spacing-loop(".mx-", "margin");

// Paddings
@include spacing-loop(".pt-", "padding-top");
@include spacing-loop(".pr-", "padding-right");
@include spacing-loop(".pb-", "padding-bottom");
@include spacing-loop(".pl-", "padding-left");
@include spacing-loop(".px-", "padding");





//Colour Palette theme
$primary : $picton-blue;
$secondary :$resolution-blue;


.page-content {
    height: 100%;
    // width: 100%;
    padding: 0 20px;
}

.bcg-white{
    background-color: white;
}

a { //Remvoing default underline and blur color decoration for link tags
    text-decoration: none !important;
    color: unset;
}


//Buttons
.tbc-button {
    text-transform: none !important;
    padding: 4px 16px 4px 16px !important;
    font-size: 16px !important;
    // height: 36px !important;
    // text-wrap: nowrap !important;
    margin-left: 2px;
    margin-right: 2px;
    // margin-top: 1px;
    // margin-bottom: 1px;



   &.btn-primary, &.btn-add, &.btn-apply, &.btn-search, &.btn-captcha, &.btn-lock {
      background-color: #3874CB !important;
      color: #FFFFFF !important;
      &:hover {
        filter: brightness(1.2) !important;
      }
    }

    &.btn-secondary, &.btn-submit, &.btn-remove, &.btn-download, &.btn-edit, &.btn-email, &.btn-customise, &.excel, &.pdf, &.btn-plus, &.btn-minus, &.photo  {
      background-color: #43A9E1 !important;
      color: #FFFFFF !important;
      &:hover {
        filter: brightness(0.95) !important;
      }
    }

    &.btn-save, &.btn-login{
      background-color: #00B34F !important;
      color: #FFFFFF !important;
        &:hover {
          filter: brightness(1.2) !important;
        }
    }

    &.btn-tertiary, &.btn-reset, &.btn-cancel, &.btn-clear, &.btn-close, &.btn-back {
        background-color: #D9D9D9 !important;
        color: #000000 !important;
        &:hover {
            filter: brightness(0.95) !important;
        }
    }

    &:disabled {
      background-color: #D9D9D9 !important;
      color: #A1A1A1 !important;
    }
}


//Tiles
.wrapper{
    height: calc(100vh);
    width: 100%;
    // padding-left: 40px;
}

.tile-layout{
    display: flex;
    width: 100%;
    margin-top: 40px;
    justify-content: flex-start;
    gap: 2em;
    // justify-content: space-evenly;
    .tile{
        width: 15%;
    }
}

.flex {
    display: flex;
}

.ai-center{
    align-items: center !important;
}
.text-left{
    text-align: left !important;
}
.gap20 {
    gap: 20px;
}


.minw-200 {
    min-width: 200px !important;
}

.minw-185 {
    min-width: 185px !important;
}

.minw-400 {
    min-width: 400px !important;
}

.text-capitalize {
    text-transform: capitalize;
}

.cursor{
    cursor: pointer;
}

.text-center {
    text-align: center;
}

.text-right {
  text-align: right;
}

.jc-center {
  justify-content: center;
}

.box-center {
    margin: auto;
    width: 50%;
    padding: 10px;
}

.arabic {
  font-size: 20px !important;
  .MuiTypography-root{
    font-size: 20px !important;
  }

}

.jc-space-between {
  justify-content: space-between;
}