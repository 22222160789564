@import '../src/assets/fonts/fonts.css';
@import './global.scss';

@font-face {
  font-family: 'SCProsperSans';
  src: url(../src/assets/fonts/SCProsperSans-Regular.ttf);
}

body {
  margin: 0;
  font-family: SCProsperSans !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ck .ck-insert-table-dropdown__grid {
  padding: var(--ck-insert-table-dropdown-padding) var(--ck-insert-table-dropdown-padding) 0;
  width: calc(var(--ck-insert-table-dropdown-box-width)* 10 + var(--ck-insert-table-dropdown-box-margin)* 20 + var(--ck-insert-table-dropdown-padding)* 1) !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
